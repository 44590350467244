// http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
export default function hashString (string) {
    var hash = 0, i, chr, len;

    if (string.length === 0) {
        return hash;
    }

    for (i = 0, len = string.length; i < len; i++) {
        chr   = string.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
